.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.signup-cover {
  object-fit: cover;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.opacity-zero {
  opacity: 0;
}

.animate-fade-down {
  opacity: 0;
  transform: translateY(-50px);
  animation: fadeDown 0.75s ease-out forwards;
}

@keyframes fadeDown {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-right {
  opacity: 0;
  transform: translateX(-50px);
  animation: fadeRight 0.75s ease-out forwards;
}

@keyframes fadeRight {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-fade-left {
  opacity: 0;
  transform: translateX(50px);
  animation: fadeLeft 0.75s ease-out forwards;
}

@keyframes fadeLeft {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes zoomInOut {
  0%,
  100% {
    transform: scale(0.85);
  }
  50% {
    transform: scale(1);
  }
}

.zoom-in-out {
  animation: zoomInOut 1.5s ease-in-out infinite;
}

@keyframes leftRightMoveAndFade {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px); /* Moves to the right */
  }
  100% {
    transform: translateX(0%); /* Moves to the right */
  }
}

.left-to-right-move-and-fading {
  animation: leftRightMoveAndFade 2s infinite;
}

@keyframes UpDownMove {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Moves to the right */
  }
  100% {
    transform: translateY(0%); /* Moves to the right */
  }
}
.up-to-down-move {
  animation: UpDownMove 3.5s infinite;
}

@keyframes DownUpMove {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px); /* Moves to the right */
  }
  100% {
    transform: translateY(0%); /* Moves to the right */
  }
}
.down-to-up-move {
  animation: DownUpMove 3.5s infinite;
}
